export enum Source {
  YOUTUBE = 'youtube',
  MEDIUM = 'medium'
}

export interface IMediumNewsCard {
  author: string
  categories: []
  content: string
  description: string
  enclosure: {
    link?: string
    thumbnail?: string
    type?: string
  }
  guid: string
  link: string
  pubDate: string
  thumbnail: string
  title: string
}

export interface IYoutubeNewsCard {
  etag: string
  id: {
    kind: string
    videoId: string
  }
  kind: string
  snippet: {
    channelId: string
    channelTitle: string
    description: string
    liveBroadcastContent: string
    publishTime: string
    publishedAt: string
    thumbnails: {
      default: IThumbnail
      high: IThumbnail
      medium: IThumbnail
    }
    title: string
  }
}

export interface IThumbnail {
  height: number
  url: string
  width: string
}

export interface INormalizedItem {
  link: string
  description: string
  preview: string
  title: string
  date: string
  source: Source
  sourceIcon: string
}
