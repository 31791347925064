export default function globalDateFormatter(dateToFormat: string) {
  const date = new Date(dateToFormat);

  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes} ${month} ${day}, ${year}`;
}
