import { IMediumFeedResponse, IYoutubeFeedResponse } from '@/widgets/NewsLine/lib/types/response';
import {
  computed, Ref, ref, watchEffect,
} from '@vue/composition-api';
import { IMediumNewsCard, IYoutubeNewsCard } from '../types/cards';

export enum Keyword {
  YOUTUBE_KEY_WORD = 'AMA',
  MEDIUM_KEY_WORD = 'TCGWorldRSSFeed',
}

export default function useLatestFeed(mediumFeed: Ref<IMediumFeedResponse | null>, youtubeFeed: Ref<IYoutubeFeedResponse | null>) {
  const latestFeed = ref<(IYoutubeNewsCard | IMediumNewsCard)[]>([]);

  // const mediumFiltered = computed(() => {
  //   if (!mediumFeed.value) {
  //     return [];
  //   }
  //
  //   return mediumFeed.value.items.filter((item) => {
  //     return item.content.includes(Keyword.MEDIUM_KEY_WORD);
  //   });
  // });

  watchEffect(() => {
    if (!mediumFeed.value || !youtubeFeed.value) {
      latestFeed.value = [];
      return;
    }

    latestFeed.value = [...youtubeFeed.value.items, ...mediumFeed.value.items].sort((a, b) => getTime(b) - getTime(a));
  });

  function getTime(item: IYoutubeNewsCard | IMediumNewsCard) {
    const youtubeCard = item as IYoutubeNewsCard;
    const mediumCard = item as IMediumNewsCard;

    if (youtubeCard?.snippet?.publishTime) {
      return new Date(youtubeCard.snippet.publishTime).getTime();
    }

    return new Date(mediumCard.pubDate).getTime();
  }

  return {
    latestFeed,
  };
}
