import { ref, readonly } from '@vue/composition-api';
import wretch from 'wretch';
import { IMediumFeedResponse } from '../types/response';

const mediumRSSUrl = 'https://medium.com/feed/@TCG_World';
const RSSConvertor = 'https://api.rss2json.com/v1/api.json?rss_url=';
const mediumRSSUrlWithConvertor = `${RSSConvertor}${mediumRSSUrl}`;

export default function useMediumFeed() {
  const mediumFeed = ref<IMediumFeedResponse | null>(null);
  const loading = ref(false);

  loading.value = true;

  wretch(mediumRSSUrlWithConvertor)
    .get()
    .json((json) => {
      mediumFeed.value = json;
    })
    .finally(() => {
      loading.value = false;
    });

  return {
    mediumFeed,
    loading,
  };
}
