import {
  onMounted,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import wretch, { WretchAddon } from 'wretch';
// @ts-ignore
// There is a warning "Has no exported member" but it is exporting :(
import AbortAddon, { AbortWretch, AbortResolver } from 'wretch/dist/addons/abort';
import { Feed } from '../../ui/NewsLine.vue';
import { IYoutubeNewsCard } from '../types/cards';
import { IYoutubeFeedResponse } from '../types/response';

export default function useYoutubeFeed(activeFeed: Ref<Feed>) {
  const youtubeFeed = ref<IYoutubeFeedResponse | null>(null);
  const loading = ref(false);

  let pageToken = '';
  let isLastPage = false;
  let abortController: AbortController | null = null;

  async function getNextBatch() {
    if (loading.value) {
      return {
        isLastPage,
      };
    }

    const data = await fetchFeed();

    if (youtubeFeed.value) {
      youtubeFeed.value.items.push(...filterDuplicates(data.items));
    }

    return {
      isLastPage,
    };
  }

  async function fetchFeed(): Promise<IYoutubeFeedResponse> {
    if (abortController) {
      abortController.abort();
    }

    abortController = new AbortController();

    loading.value = true;
    const data: IYoutubeFeedResponse = await wretch(getYoutubeApi())
      .addon(AbortAddon() as WretchAddon<AbortWretch, AbortResolver>)
      .signal(abortController)
      .get()
      .json();
    pageToken = data.nextPageToken || '';

    if (!data.nextPageToken) {
      isLastPage = true;
    }

    loading.value = false;
    return data;
  }

  function getYoutubeApi() {
    const isLatest = activeFeed.value === Feed.LATEST;
    return `${process.env.VUE_APP_API_LOCATION}/api/v1/news/youtube/?page_token=${pageToken}&latest=${isLatest}`;
  }

  function filterDuplicates(items: IYoutubeNewsCard[]) {
    if (!youtubeFeed.value) {
      return [];
    }

    const lastPage = youtubeFeed.value.items.slice(youtubeFeed.value.items.length - 12);

    return items.filter((item) => {
      let isValid = true;

      lastPage.forEach((lastPageItem) => {
        if (item.id.videoId === lastPageItem.id.videoId) {
          isValid = false;
        }
      });

      return isValid;
    });
  }

  watch(activeFeed, async () => {
    if (activeFeed.value === Feed.MEDIUM) {
      return;
    }

    pageToken = '';
    youtubeFeed.value = null;
    youtubeFeed.value = await fetchFeed();
  });

  onMounted(async () => {
    youtubeFeed.value = await fetchFeed();
  });

  return {
    youtubeFeed,
    loading,
    getNextBatch,
  };
}
