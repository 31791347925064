import {
  IMediumNewsCard,
  INormalizedItem,
  IYoutubeNewsCard,
  Source,
} from '../types/cards';

type Item = IMediumNewsCard | IYoutubeNewsCard;

export default function normalize(item: Item): INormalizedItem {
  const parsers = {
    [Source.MEDIUM]: mediumParser,
    [Source.YOUTUBE]: youtubeParser,
  };

  const resolvedParser = parsers[getSource(item)];

  return resolvedParser(item);
}

function getSource(item: Item): Source {
  const mediumItem = item as IMediumNewsCard;
  const youtubeItem = item as IYoutubeNewsCard;

  if (mediumItem.author) {
    return Source.MEDIUM;
  }

  return Source.YOUTUBE;
}

function mediumParser(item: Item): INormalizedItem {
  const mediumItem = item as IMediumNewsCard;

  const parsedHtml = mediumHtmlContentParser(mediumItem.content);

  return {
    link: mediumItem.link,
    description: parsedHtml.description,
    title: mediumItem.title,
    date: mediumItem.pubDate,
    source: Source.MEDIUM,
    sourceIcon: 'https://d1ak9kdvdopy8s.cloudfront.net/items/medium_final.png',
    preview: parsedHtml.preview,
  };
}

function mediumHtmlContentParser(content: string) {
  const domParser = new DOMParser();
  const docNode = domParser.parseFromString(content, 'text/html');

  const preview = docNode.getElementsByTagName('img')?.[0]?.src || '';
  const description = docNode.getElementsByTagName('p')?.[1].textContent || '';

  return {
    preview,
    description,
  };
}

function youtubeParser(item: Item): INormalizedItem {
  const youtubeItem = item as IYoutubeNewsCard;

  return {
    link: `https://www.youtube.com/watch?v=${youtubeItem.id.videoId}`,
    description: youtubeItem.snippet.description,
    title: youtubeItem.snippet.title,
    date: youtubeItem.snippet.publishTime,
    source: Source.YOUTUBE,
    sourceIcon: 'https://d1ak9kdvdopy8s.cloudfront.net/items/youtube.png',
    preview: youtubeItem.snippet.thumbnails.high.url,
  };
}
